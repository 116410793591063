/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/** @namespace Scandipwa/Util/Date/convertTimeStamp */
export const convertTimeStamp = (datetime) => datetime.replace(/\s+/, 'T');

/** @namespace Scandipwa/Util/Date/convertDateFormat */
export const convertDateFormat = (
    timestamp,
    dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
) => new Date(convertTimeStamp(timestamp)).toLocaleDateString(undefined, dateOptions);

/** @namespace Scandipwa/Util/Date/convertDateForCountry */
export const convertDateForCountry = (timestamp, countryCode = undefined) => {
    const datetime = convertTimeStamp(timestamp);

    return {
        datetime,
        date: new Date(datetime)
            .toLocaleDateString(countryCode?.toUpperCase(), { month: 'short', day: '2-digit', year: 'numeric' })
    };
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import BlogCategories from '../../query/BlogCategories.query';
import { updateCategories } from './Categories.action';

/** @namespace Blog/Store/Categories/Dispatcher/CategoriesDispatcher */
export class CategoriesDispatcher {
    async handleData(dispatch, options = {}) {
        try {
            const { categories = {} } = await fetchQuery(BlogCategories.getQuery(options));

            this.onSuccess(categories, dispatch);
        } catch (errors) {
            this.onError(errors, dispatch);
        }
    }

    onSuccess(categories, dispatch) {
        dispatch(updateCategories(categories));
    }

    onError(errors, dispatch) {
        const [{ message }] = errors;

        dispatch(showNotification('error', 'Error fetching Categories!', message));
    }
}

export default new CategoriesDispatcher();
